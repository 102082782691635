.rotate-tripscorecard-landscape {
    group-trip-scorecard {
        width: calc(100% - var(--ion-safe-area-top));
        padding-right: 0;
        padding-right: calc(constant(var(--ion-safe-area-top)));
        padding-right: calc(env(var(--ion-safe-area-top)));
    }

    &::part(content) {
        width: 90%;
    }

    /* rotate on mobile */
    @media only screen and (max-width: 450px) {
        transform: rotate(270deg);
        width: 100vh;

        &::part(content) {
            width: 100%;
        }
    }
}
