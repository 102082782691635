.legal-header {
    line-height: 1.5;
}

.legal-content .main-header {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 20px;
    color: var(--ion-color-dark);
}

.legal-content .header {
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 20px;
    color: var(--ion-color-dark);
}

.legal-content .sub-header {
    font-size: 15px;
    margin-bottom: 20px;
    font-weight: bold;
    color: var(--ion-color-dark);
}

.legal-content .paragraph {
    font-size: 15px;
    margin-bottom: 20px;
}

.legal-content ul {
    list-style-type: square;
}

.legal-content ul > li > ul {
    list-style-type: circle;
}

.legal-content ul > li > ul > li > ul {
    list-style-type: square;
}

.legal-content ol li {
    font-family: Arial;
}
