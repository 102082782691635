/* global custom styles for tippy popover help  */
.tippy-box[data-theme~="tippyCustom"] {
    background-color: var(--ion-color-primary);
    color: white;
    border-radius: 10px;
}

.tippy-box[data-theme~="tippyCustom"][data-placement^="top-end"] > .tippy-arrow::before {
    color: var(--ion-color-primary);
}

.tippy-box[data-theme~="tippyCustom"][data-placement^="bottom-end"] > .tippy-arrow::before {
    color: var(--ion-color-primary);
}

@keyframes tippyBounceDown {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(20px); //-20
    }
    60% {
        transform: translateY(10px); //-10
    }
}

@keyframes tippyBounceUp {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-20px); //-20
    }
    60% {
        transform: translateY(-10px); //-10
    }
}

.tippy-box[data-animation="bounce-down"][data-state="visible"] {
    animation-name: tippyBounceDown;
    animation-delay: 0s;
    animation-iteration-count: 2;
    animation-duration: 1.5s;
}

.tippy-box[data-animation="bounce-up"][data-state="visible"] {
    animation-name: tippyBounceUp;
    animation-delay: 0s;
    animation-iteration-count: 2;
    animation-duration: 1.5s;
}
