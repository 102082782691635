.gu-mirror.doDragTeamPlayer {
    position: fixed !important;
    z-index: 9999 !important;
    /* border-width: 1px;
    border-color: grey;
    border-radius: 16px;
    border-style: solid; */
    border-radius: 16px;
    --background: var(--ion-color-primary);
    text-align: initial;
}
