//this ia globl class
.leaderboardSlides {
    .swiper-wrapper {
        margin-top: 30px !important;
    }

    .swiper-pagination {
        top: 2px !important;
    }
}
