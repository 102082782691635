.gu-mirror.doDragEventPlayer {
    position: fixed !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity = 80);
    text-align: center;
    border-width: 1px;
    border-color: gray;
    border-style: solid;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    justify-content: space-evenly;
    -webkit-justify-content: space-evenly;
    height: 75px;
    padding-top: 5px;
    font-family: -apple-system, "Helvetica Neue", "Roboto", sans-serif;
    transform: scale(1.1); //zoom an element when you 'pick it up'

    .teeTimePlayer {
        text-align: center;
        padding: 0px 4px 0px 4px;
        height: 75px;

        .name {
            font-size: 12px;
            margin: 0px;
            font-family: Arial, Helvetica, sans-serif;
        }

        .container {
            position: relative;
            text-align: center;
        }

        .top-right {
            position: absolute;
            top: -3px;
            right: 6px;
            font-size: 20px;
            color: var(--ion-color-primary);
        }

        .top-left {
            position: absolute;
            top: 3px;
            left: 2px;
            color: var(--ion-color-primary);
        }
    }
}

.gu-transit.doDragEventPlayer {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity = 20);
}
