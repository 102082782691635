// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** white **/
  --ion-color-white: #ffffff;
  --ion-color-white-a: #f7f7f7;
  --ion-color-white-b: #f0f0f0;
  --ion-color-white-c: #e0e0e0;
  --ion-color-white-d: #c2c2c2;

  /** black **/
  --ion-color-black: rgba(#000000, 0.8);
  --ion-color-black-a: #1a1a1a;
  --ion-color-black-b: #333333;
  --ion-color-black-c: #666666;
  --ion-color-black-d: #999999;

  /* what's new */
  --ion-color-whatsnew-background: #051d42;
  --ion-color-whatsnew: #35ccff;

  /** primary **/
  --ion-color-primary: #0d47a1;
  --ion-color-primary-contrast: var(--ion-color-white);

  /** secondary **/
  --ion-color-secondary: #01bfff;

  /** teriary **/
  --ion-color-teriary: #f0f8ff; //#d4ecff;

  /** warning **/
  --ion-color-warning: #c4c43c;

  /** danger **/
  --ion-color-danger: #f53d3d;

  /** dark **/
  --ion-color-dark: #001602;

  /** light **/
  --ion-color-light: #e0e0e0;

  /** yes/no **/
  --ion-color-yes: #66b266;
  --ion-color-no: #ff6666;

  /* scorecard colors */
  --ion-color-hole: var(--ion-color-secondary);
  --ion-color-par: lightgray;
  --ion-color-hdcp: var(--ion-color-light);

  /** tabbar **/
  --ion-color-tabbar: #d4ecff;

  /* header */
  --ion-toolbar-background: var(--ion-color-white);

  /** background **/
  --ion-background-color: var(--ion-color-white);
  /* --ion-color-background-contrast: var(--ion-color-white); */

  /* theme */
  --ion-color-theme-color-1: #0d47a1;
  --ion-color-theme-color-2: #0d47a1;
  --ion-color-theme-color-3: #5e5874;

  /* introduction header */
  --introduction-header-base: transparent;
  --introduction-header-contrast: var(--ion-color-white);

  /* button */
  //--button-base: var(--ion-color-theme-color-1);
  //--button-contrast: var(--ion-color-white);
  --background-hover: var(--ion-color-black);
  --background-activated: var(--ion-color-theme-color-1) !important;

  /* change desktop style */
  $custom-grid-max-width: 768px;

  /* for the PWA gutters */
  ion-grid {
    --ion-grid-width: #{$custom-grid-max-width};
  }

  ion-header ion-toolbar[fixed],
  ion-footer ion-toolbar[fixed] {
    --padding-end: calc((100% - #{$custom-grid-max-width}) / 2);
    --padding-start: calc((100% - #{$custom-grid-max-width}) / 2);
  }
}
