@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./theme/legal.css";
@import "tippy.js/dist/tippy.css";
@import "tippy.js/dist/svg-arrow.css";
@import "./app/components/chat-bot/chat-bot.global.scss";
@import "./app/directives/popover/popover.directive.global.scss";
@import "./app/pages/team-popover/team-popover.global.scss";
@import "./app/pages/event-detail/event-detail.global.scss";
@import "./app/pages/group-trip-scorecard/group-trip-scorecard.global.scss";
@import "./app/pages/event-scorecard/event-scorecard.global.scss";
@import "./app/pages/event-scoring/event-scoring.global.scss";
@import "~swiper/swiper-bundle.min.css";
@import "~swiper/swiper.min.css";
@import "./app/pages/member-list/member-list.global.scss";
@import "./app/pages/contact-search/contact-search.global.scss";
@import "./app/pages/group-trip-detail/group-trip-detail.global.scss";

//style for validation item
ion-item.validation {
  --min-height: 10px;
}

//style for validation label
ion-item.validation ion-label {
  font-size: small !important;
  margin-top: 0px;
  margin-bottom: 0px;
  --color: var(--ion-color-danger) !important;
}

//validation error style
ion-label.invalid,
ion-text.invalid {
  --color: var(--ion-color-danger) !important;
}

ion-note.instructional {
  display: block;
  text-align: center;
  padding-right: 10px;
  padding-left: 10px;
  color: #b3b3b3;

  .primary {
    color: var(--ion-color-primary);
  }

  ion-icon {
    vertical-align: middle;
  }
}

ion-note.message {
  display: block;
  text-align: center;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 16px;
  color: #737373;

  ion-icon {
    vertical-align: middle;
  }
}

// used for large avatars
.avatar-large {
  margin: auto;
  width: 86px !important;
  height: 86px !important;
  z-index: 10; //needed to make the entire avatar tapable as it was underneath another layer

  img {
    margin: auto;
    width: 100% !important;
    height: 100% !important;
  }
}

.avatarLargeBorder {
  width: 90px;
  height: 90px;
  border-width: 4px;
  border-style: solid;
  border-radius: 50%;
  padding: 2px;
}

// used for medium avatars
.avatar-small {
  /* this was needed to fix a md styling issue */
  width: 54px !important;
  height: 51px !important;

  img {
    margin: auto;
    width: 50px !important;
    height: 50px !important;
  }
}

ion-avatar {
  height: 55px;
  width: 55px;

  img {
    max-width: 100% !important;
    max-height: 100% !important;
    border-radius: 50% !important; // half for rounding
  }
}

ion-avatar[size="small"] {
  height: 35px;
  width: 35px;

  img {
    max-width: 100% !important;
    max-height: 100% !important;
    border-radius: 50% !important; // half for rounding
  }
}

.guest {
  ion-col {
    padding-top: 5px;

    ion-avatar {
      min-height: none !important;
      margin-bottom: 5px !important;
      margin-right: 10px !important;
      margin-top: 0px !important;
    }

    p {
      color: var(--ion-color-white-d);
    }
  }

  .guestName {
    border-top-width: 0.001em;
    border-top-style: solid;
    border-top-color: #c2c2c2;
    padding-top: 5px;
    padding-left: 0px;
  }
}

.member {
  ion-col {
    ion-avatar {
      min-height: none !important;
      margin-bottom: 5px !important;
      margin-right: 10px !important;
      margin-top: 0px !important;
    }
  }
}

ion-fab-list {
  right: 0px;
  align-items: flex-end;
}

/* is this even used */
.footer {
  bottom: 0px;
  bottom: calc(constant(safe-area-inset-bottom) + 0px);
  bottom: calc(env(safe-area-inset-bottom) + 0px);
}

/* move pagination below sliders */
ion-slides.postSlides {
  /* show the pagination at the bottom */
  overflow: visible !important;
  margin-bottom: 20px; //make room for the pagination bullets

  .swiper-pagination {
    bottom: -20px !important;
  }

  /* set inactive slide bullet color */
  .swiper-pagination-bullet {
    --background: var(--ion-color-black) !important;
  }
}

/* when needed must specify this class in the slider options IS THIS USED ANYMORE */
.swiperPaginationbullets {
  width: 100%;
  bottom: 15px !important;
  bottom: calc(constant(safe-area-inset-bottom) + 15px) !important;
  bottom: calc(env(safe-area-inset-bottom) + 15px) !important;

  .swiper-pagination-bullet {
    margin: 0 4px;
  }
}

.matchSlides {
  .swiper-wrapper {
    margin-bottom: 20px !important;
  }

  ion-slide {
    text-align: start;
  }
}

/* @-webkit-keyframes slidingAnimation {
  0% {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(-70px, 0, 0);
    -webkit-transform: translate3d(-70px, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
}

.itemSlidingAnimationList {
  -webkit-animation: slidingAnimation;
  animation: slidingAnimation;
  -webkit-animation-duration: 1000ms;
  animation-duraton: 1000ms;
} */

.gu-hide {
  display: none !important;
}

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.showHide {
  margin-right: 0px !important;
  margin-bottom: -18px !important;
  padding-right: 0px !important;
}

.md .custom-modal {
  padding-top: 40px !important;
  padding-top: calc(constant(safe-area-inset-top) + 40px) !important;
  padding-top: calc(env(safe-area-inset-top) + 40px) !important;
  background-color: rgba(0, 0, 0, 0.5);

  .modal-wrapper {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;

    //when web round the bottom corners
    @media only screen and (min-width: 768px) and (min-height: 768px) {
      border-bottom-left-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
    }

    .ion-page {
      border-top-left-radius: 10px !important;
      border-top-right-radius: 10px !important;

      //when web round the bottom corners
      @media only screen and (min-width: 768px) and (min-height: 768px) {
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
      }
    }
  }

  ion-toolbar {
    padding-top: 0px !important;
  }
}

.custom-modal-auto-height {
  align-items: flex-end;
  background-color: rgba(0, 0, 0, 0.1);

  /* this is to fix height: auto defect - start */
  --height: auto;
  --max-height: 90%;
  --overflow: auto;

  > .ion-page {
    position: static;
    contain: initial;
  }
  /* this is to fix height: auto defect - end */

  &::part(content) {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }

  ion-toolbar {
    padding-top: 0px !important;
  }
}

//turn off the arrow on popovers, when the popover was align to the left the arrow didn't display properly
.popover-no-arrow {
  .popover-arrow {
    display: none;
  }
}

//this fixes an issue that prevented a click event within avatar.component within a ion-card
a ion-label,
button ion-label {
  pointer-events: all;
}

.customCard {
  background-color: white;

  ion-card-header {
    padding: 0px;

    ion-grid {
      height: 50px;

      ion-col {
        width: 50%;
        padding: 5px 10px 10px 10px;
      }

      ion-col:last-of-type {
        text-align: end;
      }
    }
  }

  ion-card-content {
    padding: 0px 0px 10px 0px !important;
    padding-inline-start: 10px !important;
    padding-inline-end: 10px !important;

    h1 {
      color: var(--ion-color-primary);
    }
  }
}

.coverAvatar {
  position: relative;
  margin-bottom: 20px;

  .cover {
    position: relative;
    height: 100px;

    img {
      height: 100px;
      width: 100%;
      object-fit: cover;
    }
  }

  .avatar {
    margin: auto;
    margin-top: -42px;
    z-index: 10;

    ion-avatar {
      position: relative;
      border: 3px white solid;
      background-color: white;
    }
  }

  .infoActionBar {
    --background: white;
    margin-top: -42px;
    width: 100%;
    --min-height: 32px;

    &.ios {
      --padding-start: 10px;
      --inner-padding-end: 0px;
    }

    ion-note.md {
      padding-top: 5px;
    }
  }
}

//card footer and button
.buttonBar {
  ion-row {
    ion-col {
      padding: 0px 1.5px 0px 1.5px;

      ion-button {
        width: 100%;
        font-size: 15px;
        margin: 0px;
        height: 42px;
        --padding-start: 20px;
        --padding-end: 20px;
      }
    }

    ion-col:first-child {
      padding: 0px 1.5px 0px 0px;
    }

    ion-col:last-child {
      padding: 0px 0px 0px 1.5px;
    }

    ion-col:only-child {
      padding: 0px;
    }
  }
}

//login, registration, forgot password styles
.auth-image {
  width: 120px !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

//login, registration, forgot password styles
.auth-input {
  background-color: var(--ion-color-white-b);
  border-radius: 24px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-top: 15px;
  display: flex;
  font-size: 14px;

  ion-input {
    input {
      -webkit-box-shadow: 0 0 0px 1000px var(--ion-color-white-b) inset;
    }
  }

  ion-icon {
    padding: 10px;
    font-size: large;
  }
}

//login, registration, forgot password styles
.auth-title {
  color: var(--ion-color-primary);
  margin-top: 0px;
  font-size: 30px;
}

//login, registration, forgot password styles
.auth-instructions {
  color: var(--ion-color-primary);
  margin-top: 0px;
  margin-bottom: 15px;
  text-align: left;
}

//login, registration, forgot password styles
.auth-options {
  button {
    margin: 0px;
    padding: 0px;
    font-size: 1.4rem;
  }
}

//login, registration, forgot password styles
div.invalid > .item-inner {
  border-bottom-color: var(--ion-color-danger) !important;
}

//login, registration, forgot password styles
div.validation {
  min-height: 10px;
  margin-left: 5px;
  margin-top: 0px;
  margin-bottom: 10px;
}

//login, registration, forgot password styles
div.validation ion-label {
  font-size: x-small;
  margin-top: 0px;
  margin-bottom: 0px;
  color: var(--ion-color-danger);
}

//login, registration, forgot password styles
div.invalid {
  color: var(--ion-color-danger);
}

//used to hide the footer when the keyboard is visible (this will hide all footers, this would have to be change if just some footer need to be hidden)
body.keyboard-is-open {
  .hideFooterWhenKeyboard {
    display: none;
    visibility: hidden;
    height: 0px;
  }
}

//centers camera icon over the large avatar
.avatarImageOverlay {
  display: flex;
  align-items: center;
  justify-content: center;

  ion-icon {
    position: absolute;
    color: var(--ion-color-white-c);
    font-size: 30px;
  }
}

ion-back-button {
  --color: var(--ion-color-white-a) !important;
  --color-activated: var(--ion-color-white-a) !important;
}

/* position=stacked didn't work for some ion-items with postion=stacked on android, this is to resolve this issue */
ion-item {
  ion-label {
    /* these two styles are set to override an ionic style issue */
    max-width: unset !important;
    pointer-events: unset !important;

    ion-text {
      font-family: var(--ion-font-family, inherit);
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-flex;

      &.labelPositionStacked {
        font-size: 14px;
        margin-bottom: 10px;
      }

      ion-icon {
        font-size: 18px;
      }
    }

    .toggle {
      display: flex !important;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: -5px;

      ion-toggle {
        margin-left: auto;
      }
    }
  }

  .readOnly {
    color: var(--ion-color-step-600, #666) !important;
  }

  /* fixes issue with md slot=start margin */
  avatar.component {
    margin-right: 16px;
  }
}

/* position=stacked didn't work for some ion-items with postion=stacked on android, this is to resolve this issue */
.md ion-item {
  ion-label {
    ion-label {
      margin-bottom: 6px !important;
    }
  }
}

.textSmall {
  font-size: 13.6px;
}

//preserves the text formatting (spaces, line returns, etc.) of a div field
.preserveFormat {
  white-space: pre-wrap;
  font-size: 17px !important;
}

/* give the list header a style  */
ion-list-header {
  border-bottom-style: solid;
  border-bottom-width: 0.55px;
  border-bottom-color: #f2f2f2;
  background-color: #f2f2f2;
  color: var(--ion-color-primary);
}

/* give the list header a style  */
ion-item-divider {
  border-bottom-style: solid;
  border-bottom-width: 0.55px;
  border-bottom-color: #f2f2f2;
  color: var(--ion-color-primary);
}

.counter {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;

  ion-button {
    width: 40px;
    height: 40px;
    --padding-start: 0px;
    --padding-end: 0px;
  }
}

.counterSmall {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;

  ion-button {
    width: 30px;
    height: 30px;
    --padding-start: 0px;
    --padding-end: 0px;
  }
}

/* turn off last border of a group list */
.itemGroupLines {
  contain: unset; //fixes a weird display issue where there's a thin line at the bottom of the list

  ion-item:last-of-type {
    //this turns off the border of the list item
    --inner-border-width: 0 !important;
  }
}

/* turn off last border of a list */
.itemLines {
  contain: unset; //fixes a weird display issue where there's a thin line at the bottom of the list

  ion-item:last-of-type {
    //this turns off the border of the list item
    --inner-border-width: 0 !important;
  }

  //this is used when there's a parent element between the grand parent (.itemLines) and the grandchild (ion-item:last-of-type)...this might happen when a componenet is used within a ion-list
  *:last-of-type > ion-item:last-of-type {
    --border-width: 0 !important;
  }
}

/* turn off last border of a virtual scroll item list */
.itemVirtualLines {
  contain: unset; //fixes a weird display issue where there's a thin line at the bottom of the list

  ion-item:last-of-type {
    --inner-border-width: 0 !important;
  }
}

/* set the hover and activated colors */
ion-button:not(.button-clear) {
  --background-hover: var(--ion-color-secondary);
  --background-activated: var(--ion-color-secondary);
}

/* when full screen/desktop round all modal corners */
@media only screen and (min-height: 600px) and (min-width: 768px) {
  /* .sc-ion-modal-md-h {
    --border-radius: 10px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  } */

  ion-modal {
    --border-radius: 10px;
  }

  /* make modal window a bit wider */
  .sc-ion-modal-md {
    --width: 736px;
    --height: 600px;
  }
}

/* hide the loading UI when liking a post */
ion-loading.likeLoadingCss {
  ion-backdrop {
    background-color: transparent;
  }

  .loading-wrapper {
    display: none;
  }
}

/* hole number */
.numberCircle {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  text-align: center !important;
  vertical-align: middle;
  line-height: 36px;
  position: relative;
  background: #fff;
  color: #666;
  text-align: center;
  font-size: 16px;
  background-color: lightgray;
}

.circle {
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  border-style: solid;
  border-color: lightgrey;
  border-width: 1px;
  height: 52px;
  width: 52px;

  .rightCircle,
  .leftCircle {
    height: 50px;
    width: 25px;
    display: inline-block;
  }

  .rightCircle {
    background-color: white;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
  }

  .leftCircle {
    background-color: white;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
  }
}

.circle-medium {
  border-bottom-right-radius: 33px;
  border-top-right-radius: 33px;
  border-bottom-left-radius: 33px;
  border-top-left-radius: 33px;
  border-style: solid;
  border-color: lightgrey;
  border-width: 1px;
  height: 35px;
  width: 35px;

  .rightCircle,
  .leftCircle {
    height: 35px;
    width: 17.5px;
    display: inline-block;
  }

  .rightCircle {
    background-color: white;
    border-bottom-right-radius: 33px;
    border-top-right-radius: 33px;
  }

  .leftCircle {
    background-color: white;
    border-bottom-left-radius: 33px;
    border-top-left-radius: 33px;
  }
}

.circle-small {
  border-bottom-right-radius: 26px;
  border-top-right-radius: 26px;
  border-bottom-left-radius: 26px;
  border-top-left-radius: 26px;
  border-style: solid;
  border-color: lightgrey;
  border-width: 1px;
  height: 28px;
  width: 28px;

  .rightCircle,
  .leftCircle {
    height: 26px;
    width: 13px;
    display: inline-block;
  }

  .rightCircle {
    background-color: white;
    border-bottom-right-radius: 26px;
    border-top-right-radius: 26px;
  }

  .leftCircle {
    background-color: white;
    border-bottom-left-radius: 26px;
    border-top-left-radius: 26px;
  }
}

.descriptionHeader,
.gameNameHeader {
  margin-right: 5px;
  background-color: lightgray;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.description,
.gameNameDescription {
  line-height: 28px;
}

.team-popover {
  --backdrop-opacity: 0.5;
  --min-width: 90%;
  --background: var(--ion-background-color, #fff);

  .popover-content {
    width: 97%;
    border-radius: 16px;
  }
}

/* color picker popover style */
.color-picker-popover {
  .popover-content {
    --width: auto !important;
  }
}

/* tooltip popover style */
.tooltip-popover {
  .popover-content {
    background-color: var(--ion-color-primary);
    color: whitesmoke;
    font-size: small;
    padding: 5px;
    --width: auto !important;
  }
}

/* round corners on time picker */
.picker-wrapper {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

/* for fab button using chips */
ion-fab {
  ion-fab-button {
    font-size: 25px;
  }

  ion-fab-list {
    ion-chip {
      height: 48px;
      min-width: 200px;
      max-width: 200px;
      border-radius: 22px;
      background: var(--ion-color-primary);

      ion-avatar {
        height: 40px;
        width: 40px;
        min-width: 40px;
        min-height: 40px;
      }

      ion-label {
        max-height: 40px;
        color: #fff;
        font-weight: 500;
      }
    }
  }
}

/* this class vertically aligns an icon with it's text */
.iconTextSingleLine {
  display: inline-flex;
  align-items: center;
  width: 100%;

  ion-icon {
    font-size: larger;
    padding-right: 3px;
    color: var(--ion-color-black-d);
  }
}

/* styles for helpful text with 'learn More' */
.help {
  white-space: normal !important;
  max-height: 30px;
  min-height: 30px;

  ion-text {
    padding-bottom: 10px;
    font-size: 13.6px !important;
    font-weight: normal;
  }

  ion-text:nth-child(1) {
    margin-top: 10px;
    margin-right: 3px;
    color: darkgray;
  }

  ion-text:nth-child(2) {
    margin-top: 10px;
    color: var(--ion-color-primary);
  }
}

/* center top level pages  */
ion-router-outlet {
  .ion-page {
    align-items: center;

    ion-content {
      --ion-background-color: var(--ion-color-white-b);
      max-width: 768px;
    }
  }
}

.fadeText {
  overflow: hidden;
  white-space: nowrap;
  -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 65%, rgba(0, 0, 0, 0));
}

/* this is for lists that have an "add" button */
.listAddButton {
  width: 50px;
  margin-right: 0px;
}

/* this is for lists that have an "remove" button */
.listRemoveButton {
  width: 65px;
  margin-right: 0px;
}

ion-content:has(ion-fab) {
  --padding-bottom: 60px;
}

.popoverTooltip {
  border: none !important;
  .popover-content {
    padding: 5px;
    background-color: var(--ion-color-primary);
    color: white;
    border-radius: 7px;
  }
}

.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  border-top-color: var(--ion-color-primary) !important;
}

.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  border-bottom-color: var(--ion-color-primary) !important;
}

.hole {
  background-color: var(--ion-color-primary-contrast);
  color: var(--ion-color-primary);
  border: var(--ion-color-primary) 1px solid;
}

//this styles a toolbar button when it is fill=solid which is a new DAG style for primary buttons on toolbar
ion-toolbar {
  ion-button[fill="solid"] {
    --padding-start: 15px;
    --padding-end: 15px;
    font-size: 13px;
  }
}

//custom radius for DAG cards
ion-card {
  border-radius: 20px;
  box-shadow: none;
}

ion-searchbar {
  --border-radius: 24px !important;
}

//custom radius for DAG segments
.ios ion-segment {
  border-radius: 16px;

  ion-segment-button {
    --border-radius: 16px;
    --color-checked: var(--ion-color-white);
    --indicator-color: var(--ion-color-secondary);
  }
}

/* this fixes an issue that the tab bar fab notch has created */
.tabFAB {
  bottom: 60px;
  bottom: calc(constant(safe-area-inset-bottom) + 60px);
  bottom: calc(env(safe-area-inset-bottom) + 60px);
}

/* with the tabbar being position=absolute we need to add padding on the bottom so all content can scroll above the tabbar */
.tabBarPadding {
  padding-bottom: 60px;
  padding-bottom: calc(constant(safe-area-inset-bottom) + 60px);
  padding-bottom: calc(env(safe-area-inset-bottom) + 60px);
}

/* turn off the button shadow for all (ios and md) buttons */
ion-button {
  --box-shadow: none;
}

ion-button[shape="circleSmall"] {
  --border-radius: 50%;
  width: 36px;
  height: 36px;

  ion-icon {
    color: white !important;
  }
}

/* this is the style for the camera icon for the account and group avatar/cover actions */
.imageSelector {
  position: absolute;
  border: 2px white solid;
  border-radius: 50%;
  background-color: var(--ion-color-white-d);
  right: -5px;
  bottom: 0;
  width: 30px;
  height: 30px;
}

/* this is the generic styling of the help an what's new screens */
.helpWhatsNew {
  li {
    line-height: 1.5;
  }
}

.scoreCardModal {
  /* when full screen/desktop round all modal corners */
  @media only screen and (min-height: 600px) and (min-width: 768px) {
    /* make modal window a bit wider for scorecard */
    .sc-ion-modal-md {
      --width: 1100px !important;
      --height: 700px !important;
    }
  }
}

/* global style for accordion icon */
.accordionItem {
  ion-label[position="stacked"] {
    &.md {
      padding-bottom: 5px;
    }
  }

  ion-accordion-group {
    width: 100%;
    padding-bottom: 2px; //fixes a display issue with ion-item line/separator

    ion-accordion {
      ion-item {
        --padding-start: 0;
      }

      ion-datetime {
        --background: white;
      }
    }
  }

  .ion-accordion-toggle-icon {
    color: var(--ion-color-white-d);
    margin-inline-end: 0px;
  }
}

/* replicate ion-item line selector */
.lineSeparatorTop {
  border-top: solid 0.5px;
  border-top-color: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-250, #c8c7cc)));
}

/* replicate ion-item line selector */
.lineSeparatorBottom {
  border-bottom: solid 0.5px;
  border-bottom-color: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-250, #c8c7cc)));
}

ion-popover[newGroupPopover] {
  --width: 250px;
}

/* give the accordion content area a top border */
ion-accordion {
  div[slot="content"] {
    border-top: solid 0.75px;
    border-color: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-250, #c8c7cc)));
  }
}

.greyBackgroundForSegment {
  height: 100%;
  background-color: var(--ion-color-white-b);

  ion-list,
  > div {
    background: linear-gradient(to bottom, var(--ion-color-white) 1px, var(--ion-color-white-b) 39px);
  }

  ion-list-header {
    background: transparent;
  }

  ion-infinite-scroll {
    background-color: var(--ion-color-white-b);
  }
}

/* match the same padding as ionic icons */
fa-icon {
  padding-right: 3px;
  font-size: larger;
  color: var(--ion-color-black-d);
}

/* custom style for action cards */
.actionCard {
  min-height: 40px;
  background-color: var(--ion-color-teriary);
  color: var(--ion-color-primary);

  ion-card-title {
    color: var(--ion-color-primary);
  }

  .message {
    margin-top: 5px;
    font-size: 18px;
  }

  .buttonBar {
    ion-row {
      ion-col {
        ion-button {
          &.primary-action {
            --background: var(--ion-color-primary);
          }

          &.secondary-action {
            --background: var(--ion-color-white-c);
            color: var(--ion-color-primary);
          }
        }
      }
    }
  }
}

.whats-new-modal {
  .ion-page {
    background: var(--ion-color-whatsnew-background);
  }
}

.holeSelectorModal {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;

  .hole {
    margin: 6px;
    border: unset;
    width: 45px;
    height: 45px;
    line-height: 45px;

    &.played {
      background-color: var(--ion-color-white-c);

      ion-icon {
        display: block;
      }
    }

    &.current {
      background-color: var(--ion-color-secondary);
    }

    &.notPlayed {
      background-color: var(--ion-color-white-b);
      color: var(--ion-color-white-d);
    }

    ion-icon {
      position: absolute;
      display: none;
      right: -3px;
      top: -3px;
      font-size: 20px;
      color: var(--ion-color-yes);
    }
  }
}

.matchName {
  margin: 10px 10px 0 0;
  color: var(--ion-color-primary);
  font-weight: 500;
}

.matchConfig {
  font-size: smaller !important;
  color: var(--ion-color-white-d) !important;
  padding-bottom: 5px !important;
}

.holeByHoleNumbers {
  padding-bottom: 5px;
}

.item-expand-closed {
  max-height: 0;
  overflow: hidden;
  transition: max-height 300ms ease-out;
}

.item-expand-open {
  max-height: var(--max-height-expand);
  transition: max-height 300ms ease;
}

/* not sure why ionic applies the top and bottom for android but removing here */
ion-list.list-md {
  padding-top: 0px;
  padding-bottom: 0px;
}

/* fix issue with ion-segment scrolling */
ion-segment-button {
  min-width: auto;
}

.fabButtonHighlight {
  --background: var(--ion-color-secondary);
}

.emptyState {
  margin-top: 15px;

  .emptyStateIcon,
  fa-icon {
    text-align: center;
    font-size: 50px;
    color: var(--ion-color-black-d);
  }

  .emptyStateText {
    margin-top: 12px;
    margin-bottom: 12px;
    line-height: 25px;
  }
}
